import { clsx } from 'clsx';
import dynamic from 'next/dynamic';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';
import styles from '../index.module.scss';

const MobileAuthButtons = dynamic(() => import('./MobileAuthButtons'));
const MobileNavigation = dynamic(() => import('./MobileNavigation'));
const DesktopNavigation = dynamic(() => import('./DesktopNavigation'));

type Props = {
  showMenu: boolean;
  onClose: () => void;
  userExist: boolean;
  menuClasses?: { menuItem?: string };
};

export default function Navigation({
  showMenu,
  onClose,
  userExist,
  menuClasses,
}: Props) {
  const isMobile = useIsMobile('(max-width:1024px)');

  return (
    <div className={clsx(styles.menu, { [styles.showMenu]: showMenu })}>
      {isMobile ? (
        <MobileNavigation onClose={onClose} />
      ) : (
        <DesktopNavigation menuClasses={menuClasses} />
      )}

      {showMenu && !userExist && <MobileAuthButtons />}
    </div>
  );
}
